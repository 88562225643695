@mixin flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin un-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

$theme-color: #FE2C55;
$default-color: #010E55;
$padding-top: 76px;
.header-footer {
    width: 100%;
    height: 72px;
    background: $default-color;
}

.header-content {
    @include flex-row;
    justify-content: flex-end;
}

.footer-bar {
    position: fixed;
    bottom: 0;
    text-align: center;
    @include flex-column;
    justify-content: center;
}

.body-content {
    height: 100%;
    padding-top: $padding-top;
    padding-bottom: $padding-top;
}

.header-logo {
    position: absolute;
}

.logo {
    position: absolute;
    left: 120px;
}

.nav-groups {
    @include flex-row;
    .ant-anchor {
        display: flex;
    }
}

.menu-icon {
    width: 24px;
    height: 24px;
    color: #fff;
    margin: auto 20px auto 20px;
}

.header-nav {
    position: relative;
    ul {
        @include flex-row;
        margin-bottom: 0;
    }
    li {
        list-style: none;
        margin-right: 20px;
    }
    a {
        color: #fff;
    }
    margin-right: 20px;
}

.header-nav::after {
    content: '';
    position: absolute;
    display: block;
    height: 20px;
    width: 1px;
    background-color: rgba(255, 255, 255, 1);
    color: #fff;
    right: 0;
    bottom: 5px;
}

.banner {
    background-image: linear-gradient(#010E55, #130025);
    padding: 5rem;
    h1 {
        color: #fff;
    }
    h3 {
        color: #fff;
    }
}

.banner-item {
    display: flex;
    flex-direction: column;
}

#charge {
    @include flex-row;
    justify-content: center;
    align-items: baseline;
    h1 {
        margin: 0 4px 0 0;
    }
    p {
        margin: 0;
    }
}

#discount {
    padding: 4px 16px;
    width: 104px;
    height: 30px;
    /* 主题/浅 */
    background: $default-color;
    color: #fff;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: 10px auto 10px auto;
}

#buy-button {
    background: white;
    border: #000 1px solid;
    border-radius: 4px;
    width: 184px;
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    display: flex;
    flex: column nowarp;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include un-select;
    a {
        color: #000000;
    }
}

.banner-img {
    width: 355px !important;
    height: 355px !important;
    object-fit: contain;
}

.banner-button {
    padding: 8px 24px;
    height: 54px;
    margin-top: 80px;
    color: #fff;
    border-radius: 27px;
    background-color: $theme-color;
    @include flex-row;
    justify-content: center;
    cursor: pointer;
    >span>svg {
        width: 32px;
        height: 32px;
        fill: white;
        margin-right: 8px;
    }
}

.banner-button-other {
    @include flex-row;
    color: #fff;
    fill: white;
    flex-wrap: wrap;
    margin-top: 24px;
    div {
        margin: 0 6px 0 6px;
    }
    div>span>svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.banner-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.introduce {
    padding: 80px 35px 80px 35px;
}

.ant-anchor-ink {
    display: none;
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $padding-top;
    width: 100%;
    background: #010E55;
    z-index: 100;
    p {
        color: white;
    }
}

.language {
    @include flex-column;
    justify-content: center;
    p {
        margin: 0;
    }
    margin-right: 20px;
}

.login {
    @include flex-row();
    justify-content: center;
    p {
        margin: 0;
    }
    padding: 20px 0;
    margin-right: 20px;
}

.dropmenu-view {
    position: absolute;
    background: #333333;
    right: 30px;
    top: $padding-top - 12px;
    border-radius: 4px;
    @include flex-column;
    .ant-anchor-link-title {
        color: #fff;
    }
    .ant-anchor-link {
        padding: 20px;
        font-size: 1rem;
        line-height: 1;
    }
}

.custom-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    z-index: 101;
    @include flex-column;
    justify-content: center;
}

.login-panel {
    width: 320px;
    height: 320px;
    background: white;
    border-radius: 10px;
    padding: 24px;
    input {
        height: 36px;
    }
    button {
        border: 1px solid #fff;
        border-color: rgba(255, 255, 255, 1);
    }
}

.login-button {
    width: 100%;
    margin-top: 20px;
}

.ant-anchor-link-active>.ant-anchor-link-title {
    color: $theme-color;
}

.package {
    background-color: #F3F4F5;
    padding: 80px 35px 80px 35px;
}

.package-scroll {
    @include flex-row;
    justify-content: space-evenly;
    text-align: center;
}

.package-item {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    width: 100%;
    max-width: 686px;
}

.package-item:hover {
    #discount {
        background: $theme-color;
        color: black;
    }
    #buy-button {
        background: #FFCD31;
        border: white 1px solid;
    }
}

.package-content {
    text-align: center;
}

.discount-container {
    @include flex-column;
}

.download-app {
    padding: 80px 16px 80px 16px;
}

.download-app-list {
    @include flex-row;
    flex-wrap: wrap;
}

.download-app-button {
    width: 140px;
    height: 54px;
}

.download-app-item {
    flex: 45%;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 10px 20px 10px;
    padding: 20px 43px 20px 43px;
}

.download-app-item-front {
    @include flex-row;
    justify-content: center;
    h1 {
        margin: 0 0 0 10px;
    }
}

.download-app-item-before {
    @include flex-row;
    justify-content: center;
    div {
        background-color: black;
        padding: 16px;
        border-radius: 4px;
    }
    button {
        width: 140px;
        height: 54px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 4px;
        margin-left: 10px;
    }
}

.custom-ant-modal {
    .ant-modal-header {
        border-bottom: 0px solid #ffffff;
        text-align: center;
    }
    .ant-modal-footer {
        border-top: 0px solid #ffffff;
        text-align: center;
    }
    .ant-modal-body {
        text-align: center;
    }
}

//大屏
@media only screen and (min-width:1200px) {
    .menu-icon {
        display: none;
    }
    .download-app-item {
        @include flex-row;
        justify-content: space-between;
    }
    .logo {
        left: 120px;
    }
    .header-logo {
        left: 120px;
    }
}

//小屏
@media only screen and (max-width: 1199px) {
    .nav-groups {
        display: none;
    }
    .menu-icon {
        display: flex;
    }
    .download-app-item {
        @include flex-column;
        justify-content: space-between;
        flex: 45%;
    }
    .download-app-item-front {
        @include flex-column;
    }
    .logo {
        left: 10%;
    }
    .header-logo {
        left: 10%;
    }
}

@media only screen and (max-width:1000px) {
    .package-scroll {
        @include flex-column();
        justify-content: space-between;
        text-align: center;
        white-space: wrap;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .package-item {
        width: 99.4%;
        text-align: center;
        margin-right: 0px;
        @include flex-row;
        justify-content: space-between;
        a {
            div {
                margin: auto;
            }
        }
    }
    .package-content {
        text-align: start;
    }
    .discount-container {
        @include flex-row;
        justify-content: flex-start;
        align-items: baseline;
    }
    #charge {
        justify-content: flex-start;
    }
}

//pad
@media only screen and (max-width: 768px) {
    .banner-item {
        align-items: center;
        h1 {
            text-align: center;
        }
    }
    .logo {
        display: none;
    }
    .package-item {
        @include flex-column;
        justify-content: center;
    }
    .package-content {
        text-align: center;
    }
    #charge {
        justify-content: center;
    }
    .discount-container {
        flex-wrap: wrap;
    }
    .download-app-item {
        @include flex-row;
        border: 1px solid #fff;
        padding: 21px 16px 21px 16px;
        flex: 100%;
    }
    .download-app-item-front {
        @include flex-row;
        h1 {
            font-size: 20px;
        }
    }
    #popover {
        display: none;
    }
    .download-app-item-before button {
        width: 64px;
        height: 46px;
    }
}
@import "./index.scss";
.user-menu {
    display: none;
    position: absolute;
    background-color: #333333;
    top: 55px;
    right: 20px;
    border-radius: 10px;
    padding: 20px;
}

.login:hover {
    .user-menu {
        display: block;
    }
}

.user-menu:hover {
    display: block;
}

// ================================= wice =================================
.wice-header {
    width: 100%;
    height: 78px;
    background-color: #000;
    padding: 0;
}

.header-position {
    position: fixed;
    top: 0;
    @include flex-column;
    align-items: start;
    justify-content: center;
    padding-left: 10%;
}

.wice-content {
    @include flex-column;
    p {
        margin: 20px 0px;
    }
}

.wice-button {
    border: 1px solid #333;
    a {
        color: #000;
        margin: 50px 100px;
    }
    padding:10px;
    border-radius: 8px;
    margin-top: 20px;
}
@import "./index.scss";
.contact-us {
    padding: 80px 0 80px 0;
}

.contact-us-title {
    text-align: center;
}

.contact-us-form {
    width: 800px;
    margin: 40px auto;
    p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        line-height: 24px;
        color: #60616B;
    }
}

.contact-us-btn {
    margin: 0 auto;
    width: 184px;
    height: 54px;
    background: #FFCD31;
    border-radius: 4px;
    border: 1px solid #FFCD31;
}

.user-terms-item {
    margin-left: 10px;
    position: relative;
    margin-bottom: 10px;
}

.user-terms-item::before {
    content: '';
    position: absolute;
    display: block;
    height: 12px;
    width: 4px;
    background-color: $theme-color;
    color: $theme-color;
    left: -10px;
    top: 5px;
}

@media only screen and (max-width: 900px) {
    .contact-us-form {
        width: 95%;
    }
}

.trade-modal-view {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 104;
}

.tarde-modal-panel {
    position: absolute;
    width: 320px;
    height: 294px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 24px 16px;
}

.trade-modal-btn {
    width: 288px;
    height: 48px;
    font-size: 20px;
    margin: 10px auto;
    border-radius: 4px;
    padding-top: 8px;
}

.trade-modal-btn a {
    color: #000;
}

.btn-background {
    background-color: $theme-color;
}

.btn-border {
    border: 1px solid #CCCCCC;
}

.wait-point {
    width: 12px;
    height: 12px;
    background-color: #FFCD31;
    margin-left: 10px;
    border-radius: 6px;
}

.wait-plane {
    @include flex-row;
    justify-content: center;
    margin: 16px auto 50px auto;
}
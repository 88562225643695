@import "./index.scss";
.register-menu {
    @include flex-row;
    justify-content: space-between;
    #tips {
        display: none;
    }
    margin-bottom: 10px;
}

.register-panel {
    width: 320px;
    height: 300px;
    background: white;
    border-radius: 10px;
    padding: 24px;
    input {
        height: 36px;
    }
    text-align: center;
}

.register-panel-header {
    @include flex-row;
    justify-content: space-between;
    h1 {
        margin-bottom: 0;
    }
    margin-bottom: 10px;
}

.register-button {
    width: 100%;
    margin-top: 10px;
}

.verification-code-menu {
    @include flex-row;
    justify-content: space-between;
    .ant-form-item-control-input-content {
        input {
            margin-bottom: 0px;
        }
    }
    div {
        // margin-left: auto;
        // margin-right: auto;
        // padding: 10px;
        height: 40px;
    }
}

//trade
.trade-list {
    @include flex-row;
    justify-content: space-between;
    padding: 20px 120px 20px 120px;
}

.tarde-item {
    position: relative;
    flex: 1;
    @include flex-column;
    border: 1px solid #F3F4F5;
    box-sizing: border-box;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 40px 0 40px 0;
    width: 100%;
}

.trade-pay-method {
    @include flex-row;
    justify-content: space-between;
    padding: 20px 120px 20px 120px;
}

.trade-pay-method-item {
    flex: 1;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;
    @include flex-row;
    justify-content: space-between;
    padding: 18px;
}

.trade-pay-method-item-right {
    @include flex-row;
}

.trade-pay-button {
    margin-left: auto;
    margin-right: auto;
    background-color: $theme-color;
    max-width: 400px;
    min-width: 300px;
    width: 100%;
    padding: 10px 0 5px 0;
    border-radius: 4px;
}

.trade-pay-item-select-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
}

.trade-discount {
    @include flex-column;
}

@media only screen and (min-width: 1200px) {
    .tarde-item {
        margin-right: 10px;
    }
    .trade-pay-method-item {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 1000px) {
    .tarde-item {
        margin-right: 10px;
    }
    .trade-pay-method-item {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 999px) {
    .trade-list {
        @include flex-column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 20px;
    }
    .tarde-item {
        align-items: flex-start;
        padding: 10px 30px;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .trade-discount {
        @include flex-row;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
            flex: 1;
            margin: 0 5px;
        }
    }
    .trade-pay-method-item {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 879px) {
    .tarde-item {
        align-items: center;
        padding: 10px 30px;
    }
    .trade-pay-method {
        @include flex-column;
        padding: 20px 0px 20px 0px;
        margin-right: 0px;
    }
    .trade-pay-method-item {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0px;
    }
}